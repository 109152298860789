import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
export default {
  name: "MaterialCode",
  data: function data() {
    return {
      outerVisible: false,
      orderDialog: {},
      managerRadio: '',
      orderList: [{
        id: 1,
        name: '所有订单'
      }, {
        id: 2,
        name: '本学期订单'
      }],
      // 筛选
      filter: [{
        placeholder: '课程名称',
        value: '',
        type: 'select',
        list: []
      }, {
        placeholder: '物料编码类型',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '物料编码',
        value: '',
        type: 'text',
        list: []
      }],
      // 课程下拉数据
      courseList: [],
      stageList: [],
      // 列表接口传递数据
      postListData: {
        courseId: '',
        number: '',
        state: '',
        pageSize: '',
        pageIndex: ''
      },
      // 用户信息
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: 'rtl',
      drawerState: '',
      drawerData: {
        number: '',
        name: '',
        courseId: '',
        stageId: '',
        state: ''
      },
      functionGroupList: [],
      // dialog弹框-删除弹框
      delId: '',
      centerDialogVisible: false // 删除弹框显示隐藏
    };
  },

  watch: {},
  components: {},
  created: function created() {
    //获取功能组列表
    this.initCourse();
    //获取数据
    this.initData();
  },
  methods: {
    // 添加函数
    addFun: function addFun() {
      this.drawerState = 'add';
      this.drawer = true;
    },
    // 初始化课程数据
    initCourse: function initCourse() {
      var _this = this;
      //getCourse
      this.service.axiosUtils({
        requestName: "getCourseAll",
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.courseList = res.data;
            _this.filter[0].list = res.data;
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    // 初始化所选课程对应阶段
    initStage: function initStage(val) {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: "getStage",
        data: {
          courseId: val
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.stageList = res.data;
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    // 抽屉选择其他课程后，对接阶段需要跟着修改
    showStageListFun: function showStageListFun(val) {
      this.drawerData.stageId = '';
      this.initStage(val);
    },
    // 初始化数据
    initData: function initData() {
      var _this3 = this;
      this.postListData.pageSize = this.pageSize;
      this.postListData.pageIndex = this.currentPage;
      this.service.axiosUtils({
        requestName: 'getMaterialList',
        data: this.postListData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.tableData = res.data.data;
            _this3.total = res.data.total;
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this4 = this;
      var rowState = '';
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: 'changeMaterialState',
        data: {
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this4.$toast.error(res.message);
          }
        }
      });
    },
    // table-中操作函数
    detail: function detail(type, val) {
      if (type === 'edit') {
        this.initStage(val.courseId);
        this.drawerState = 'edit';
        this.drawer = true;
        this.drawerData = {
          name: val.name,
          number: val.number,
          courseId: val.courseId,
          stageId: val.stageId,
          state: val.state,
          id: val.id
        };
      } else if (type === 'del') {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else if (type === 'order') {
        this.orderDialog = {
          number: val.number,
          name: val.name
        };
        this.outerVisible = true;
      }
    },
    // drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      this.$nextTick(function () {
        this.$refs['form'].resetFields();
      });
      this.drawer = false;
    },
    // drawer抽屉-确定函数
    confirmClick: function confirmClick(formName) {
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = '';
          that.drawerData.userId = that.userInfo.userId;
          that.drawerData.roleId = that.userInfo.roleId;
          if (that.drawerState === 'edit') {
            //修改
            url = 'editMaterialCode';
          } else {
            //添加
            url = 'addMaterialCode';
          }
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.drawerData = {
                  number: '',
                  name: '',
                  courseId: '',
                  stageId: '',
                  state: ''
                };
                if (that.drawerState === 'add') {
                  that.currentPage = 1;
                }
                that.$nextTick(function () {
                  that.$refs['form'].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this5 = this;
      //删除当前的功能
      this.service.axiosUtils({
        requestName: 'deleteMaterialCode',
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this5.$toast.success(res.message);
            _this5.delId = '';
            _this5.centerDialogVisible = false;
            _this5.initData();
          } else {
            _this5.$toast.error(res.message);
          }
        }
      });
    },
    // 筛选按钮函数
    search: function search(val) {
      var that = this;
      if (this.$common.checkSearchOrAllFilter(val)) {
        var filterData = val;
        this.postListData.courseId = filterData[0].value;
        this.postListData.state = filterData[1].value;
        this.postListData.number = filterData[2].value;
      } else {
        this.postListData.courseId = '';
        this.postListData.number = '';
        this.postListData.state = '';
      }
      that.initData();
    },
    reload: function reload() {
      for (var i = 0; i < this.filter.length; i++) {
        this.filter[i].value = '';
      }
      this.postListData.number = '';
      this.postListData.courseId = '';
      this.postListData.state = '';
      this.currentPage = 1;
      this.initData();
    },
    dispenseConfirmFun: function dispenseConfirmFun() {}
  }
};